import React from 'react';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import Hero from '../components/hero';

const IndexPage = () => {
    return (
    <Layout className="font_lucida">
        <Hero />
	</Layout>
    );
};


export default IndexPage;
