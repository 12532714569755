import React from 'react';

import { Link } from 'gatsby';
import profilPicture from '../images/profil_picture.jpg';

import { FaBookOpen, FaList } from 'react-icons/fa';
import logoWoptimoSymbol from '../images/logo_woptimo_symbol.png';

const Hero = ({ siteTitle }) => {
	return (
	<section className="hero gradientBg is-fullheight-with-navbar">
		<div className="hero-body ">

			<div className="container">
                                               <div className="center columns">
                                                       <img id="profil" src={profilPicture} alt="Logo-2" />
                                               </div>
						<div id="introduction" className="center-no-flex">
							<div>
                				<p className="is-uppercase" style={{color: "white", textAlign: "center", fontWeight: "bold", fontSize: "15px"}}>
									Morgan Bouvet - Data Engineer @ Woptimo <img src={logoWoptimoSymbol} width="10px"/> 
										    						</p>
		<p style={{color: "white", textAlign: "center", fontSize: "15px", marginTop: "2vw"}}>
									Issu d'un parcours d'études dans le développement informatique, 
										j'ai saisi l'opportunité de me spécialiser dans l'expérience utilisateur.</p>
                                                <p style={{color: "white", textAlign: "center", fontSize: "15px"}}>En alternance, j'ai pu créer des pipelines d'extraction de données analytics,
										prenant en compte l'utilisation et l'automatisation, par mes collègues, de ces nouveaux outils.
	    						</p>
							</div>
							<div>
								<h1 style={{color: 'white', textAlign: "center", marginTop: "5vw", fontWeight: "bold", fontSize: "25px"}}>
									Votre curiosité est la bienvenue, qu'est ce qu'on vous sert ?
								</h1>
								<br />
							</div>
						</div>
						<div className="columns center">
							{/* <div className="column is-6">
		                                                  <Link className="button is-medium is-uppercase home_buttons text_primary" to="/au-menu"><FaBookOpen style={{ marginRight: "12px" }} size={24} color={"#123a55"} /> MENU</Link>
							</div> */}
							<div className="column">
								  <Link className="button is-medium is-uppercase home_buttons text_primary" to="/alacarte"><FaBookOpen style={{ marginRight: "12px" }} size={24} color={"#123a55"} /> Voir la carte</Link>
							</div>
				</div>
			</div>

		</div>
	</section>
  );
}
export default Hero;
